import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Iconify from '../components/iconify';
import { UserListHead } from '../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'practice_name', label: 'Practice Name', alignRight: false },
  { id: 'covered_individuals', label: 'Covered Individuals', alignRight: false },
  { id: 'activation_date', label: 'Activation Date', alignRight: false },
  { id: 'services', label: 'Services', alignRight: false },
  { id: 'created_on', label: 'Created On', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array = [{}], comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.covered_entity.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function InvoicePage() {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const filteredInvoices = applySortFilter(invoices, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredInvoices.length && !!filterName;

  const handleNewInvoice = () => {
    navigate('new', { replace: true });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = invoices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Invoice</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Box>
              <Typography variant="h4" gutterBottom>
                Invoice Management
              </Typography>
            </Box>

            <Button
              variant="contained"
              onClick={handleNewInvoice}
              startIcon={<Iconify icon="eva:plus-fill" />}
              style={{
                backgroundColor: '#0A9EF2',
              }}
            >
              New Invoice
            </Button>
          </Stack>

          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={invoices?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {loading ? (
                  <TableRow>
                    <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  invoices?.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="body2">No records found.</Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                )}

                {/* <TableBody>
                  {invoices?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, practice_name, activation_date, created_at, services, covered_individuals, status } =
                      row;
                    const selectedUser = selected.indexOf(practice_name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, practice_name)} />
                        </TableCell>
                        <TableCell align="left">{practice_name}</TableCell>
                        <TableCell align="left">{covered_individuals.join(', ')}</TableCell>
                        <TableCell align="left">{format(parseISO(activation_date), 'MM-dd-yy')}</TableCell>
                        <TableCell align="left">{services.join(', ')}</TableCell>{' '}
                        <TableCell align="left">{format(parseISO(created_at), 'MM-dd-yy')}</TableCell>
                        <TableCell align="left">{status}</TableCell>
                        <TableCell align="left">
                          <IconButton
                            aria-controls="client-actions-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleActionClick(e, index);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="client-actions-menu"
                            anchorEl={anchorEl[index]}
                            open={Boolean(anchorEl[index])}
                            onClose={handleClose}
                          >
                            <MenuItem
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={() => handleClientActions('showContract', id, status, row)}
                            >
                              <img
                                src={'/assets/icons8-eye 1.png'}
                                alt=""
                                height={'20px'}
                                width={'20px'}
                                style={{ marginRight: '8px' }}
                              />
                              Show Contract
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody> */}

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={invoices?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>

          {/* <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem onClick={handleCreateNewContract} disabled>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }} disabled>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover> */}
        </Grid>
      </Grid>
    </>
  );
}
